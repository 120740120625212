
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { formatNumber } from '@/services/utils'
import type { workspace } from '@/definitions/auth/types'

export default defineComponent({
  props: {
    workspaces: {
      type: Array as PropType<workspace[]>,
      default: () => [],
    },
  },
  setup() {
    return {
      formatNumber,
    }
  },
})

