
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import WorkspacesList from '@/components/pages/auth/WorkspacesList.vue'
import type { workspace } from '@/definitions/auth/types'

export default defineComponent({
  components: {
    PageAuth,
    WorkspacesList,
  },
  setup() {
    const workspaces = ref<workspace[]>([
      {
        title: 'Touchpoint',
        image: require('@/assets/images/tp.svg'),
        to: { name: 'auth.workspaces' },
        users: 113,
      },
      {
        title: 'HubSpot',
        image: require('@/assets/images/hubspot.svg'),
        to: { name: 'auth.workspaces' },
        users: 2949,
      },
    ])

    return {
      workspaces,
    }
  },
})
